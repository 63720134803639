<template>
  <report report="receipt" filename="Resguardos">
    <template #filters="{ apply, loading, setData, setColumns, filterKey }">
      <report-filters
        :movement-type-code="[6]"
        :loading="loading"
        :selected-columns.sync="arSelectedHeaders"
        :filter-key="filterKey"
        hide-payment-type
        hide-currency-convert
        hide-content-toggle
        @apply="apply"
        @change="setData"
        @columns="setColumns"
        @reset="onSetInitFilterData"
      />
    </template>
  </report>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import Report from "@/modules/reports/components/Report.vue";
import ReportFilters from "@/modules/reports/components/ReportFilters.vue";
import { DataTableHeader } from "@/mixins/DataTableMixin";
import { EventBus } from "@/services/event-bus";
import ReportFilterMixin from "@/modules/reports/mixins/ReportFilterMixin";

@Component({
  components: { ReportFilters, Report },
})
export default class Receipt extends Mixins(ReportFilterMixin) {
  arSelectedHeaders: DataTableHeader["value"][] = [
    "created_at",
    "order_number",
    "customer_id",
    "customer.firm.doc_formatted",
    "references",
    "total_price_value",
  ];

  onSetInitFilterData() {
    this.setFilter("movementTypeCode", [6]);
    this.setFilter("currency", 0);
    this.setFilter("signed", 1);
  }

  onSetFilterData() {
    this.setFilter("movementTypeCode", [6, 11]);
  }

  created() {
    this.setDefaultHeaders();
    this.removeHeader([
      "invoice_type.short_name",
      "currency",
      "rate",
      "currency_convert",
      "taxes",
    ]);
    this.addHeader({ text: "report.references", value: "references" }, 4);
    EventBus.on("report.before.apply", this.onSetFilterData);
  }

  beforeDestroy() {
    EventBus.off("report.before.apply", this.onSetFilterData);
  }
}
</script>
